'use client';

import { forwardRef, useState } from 'react';

import * as SelectPrimitive from '@radix-ui/react-select';
import { DropdownMenuItemView, DropdownMenuItemViewProps, NewIcon } from 'design-system';

interface SelectItem extends DropdownMenuItemViewProps {
  value: string;
}

type SelectProps<T extends SelectItem> = {
  defaultValue?: T['value'];
  options: T[];
  onSelect: (option: T['value']) => void;
};

export const Select = <T extends SelectItem>({
  options,
  onSelect,
  defaultValue = '',
}: SelectProps<T>) => {
  const [v, setV] = useState<string>(defaultValue);
  const handleSelect = (value: string) => {
    setV(value);
    onSelect(value);
  };

  return (
    <SelectRoot
      defaultValue={defaultValue}
      value={v}
      onValueChange={handleSelect}
      currentLabel={options.find((option) => option.value === v)?.label ?? ''}
    >
      {options.map((option) => {
        return (
          <SelectItem
            className="text-new-Body2-bold bg-new-white flex w-full min-w-[80px] flex-1"
            key={option.value}
            value={option.value}
          >
            <DropdownMenuItemView
              label={option.label}
              description={option.description}
              icon={option.icon}
            />
          </SelectItem>
        );
      })}
    </SelectRoot>
  );
};

export const SelectRoot = forwardRef<
  HTMLButtonElement,
  SelectPrimitive.SelectProps & {
    currentLabel: string;
  }
>(({ children, currentLabel, ...props }, forwardedRef) => {
  return (
    <SelectPrimitive.Root {...props}>
      <SelectPrimitive.Trigger
        className="bg-new-white text-new-Body2-bold data-[state=open]:border-new-gray-900 data-[state=closed]:border-new-gray-200 flex max-w-fit items-center gap-2 rounded-[20px] border-2 py-6 pl-14 pr-10"
        ref={forwardedRef}
      >
        {currentLabel}
        <NewIcon icon="arrowdown-outlined" size={20} className="fill-new-gray-900" />
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Portal>
        <SelectPrimitive.Content side="bottom" position="popper" sideOffset={8}>
          <SelectPrimitive.Viewport className="z-50 flex flex-col gap-16 overflow-hidden rounded-md border bg-white p-20 shadow-[0px_4px_16px_0px_#0000001A]">
            {children}
          </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  );
});

export const SelectItem = forwardRef<HTMLDivElement, SelectPrimitive.SelectItemProps>(
  ({ children, ...props }, forwardedRef) => {
    return (
      <SelectPrimitive.Item {...props} asChild ref={forwardedRef}>
        <div className="grid grid-cols-[20px_1fr] gap-x-8">
          <SelectPrimitive.ItemIndicator asChild>
            <div className="col-start-1 mr-auto">
              <NewIcon icon="check-outlined" size={20} className="fill-new-gray-900" />
            </div>
          </SelectPrimitive.ItemIndicator>
          <SelectPrimitive.ItemText asChild>
            <div className="col-start-2 place-self-start">{children}</div>
          </SelectPrimitive.ItemText>
        </div>
      </SelectPrimitive.Item>
    );
  },
);
