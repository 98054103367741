'use client';

import * as React from 'react';

import { cn } from 'tailwind-config';
import { Drawer as NewBottomSheetPrimitive } from 'vaul';

import { NewIcon } from '../NewIcon';

const NewBottomSheet = ({
  shouldScaleBackground = true,
  ...props
}: React.ComponentProps<typeof NewBottomSheetPrimitive.Root>) => (
  <NewBottomSheetPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
);
NewBottomSheet.displayName = 'NewBottomSheet';

const NewBottomSheetTrigger = NewBottomSheetPrimitive.Trigger;

const NewBottomSheetPortal = NewBottomSheetPrimitive.Portal;

const NewBottomSheetClose = NewBottomSheetPrimitive.Close;

const NewBottomSheetOverlay = React.forwardRef<
  React.ElementRef<typeof NewBottomSheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof NewBottomSheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <NewBottomSheetPrimitive.Overlay
    ref={ref}
    className={cn('bg-new-black-50 fixed inset-0 z-[1000]', className)}
    {...props}
  />
));
NewBottomSheetOverlay.displayName = NewBottomSheetPrimitive.Overlay.displayName;

const NewBottomSheetContent = React.forwardRef<
  React.ElementRef<typeof NewBottomSheetPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NewBottomSheetPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <NewBottomSheetPortal>
    <NewBottomSheetOverlay />
    <NewBottomSheetPrimitive.Content
      ref={ref}
      className={cn(
        'fixed inset-x-0 bottom-0 z-[1000] mx-auto flex h-auto max-w-[500px] flex-col rounded-t-[24px] bg-white px-16 pb-32',
        className,
      )}
      {...props}
    >
      <div className="mx-auto mt-4 h-2 w-[100px] rounded-full bg-neutral-100" />
      {children}
    </NewBottomSheetPrimitive.Content>
  </NewBottomSheetPortal>
));
NewBottomSheetContent.displayName = 'NewBottomSheetContent';

const NewBottomSheetHeader = ({
  className,
  closeButton = true,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { closeButton?: boolean }) => (
  <div className="relative">
    <div className={cn('grid gap-1.5 py-24', className)} {...props} />
    <NewBottomSheetClose>
      <NewIcon
        icon="xincircle-filled"
        size={24}
        className="fill-new-gray-400 absolute right-16 top-[26.5px] cursor-pointer"
      />
    </NewBottomSheetClose>
  </div>
);
NewBottomSheetHeader.displayName = 'NewBottomSheetHeader';

const NewBottomSheetFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('mt-auto flex flex-col gap-2 p-4', className)} {...props} />
);
NewBottomSheetFooter.displayName = 'NewBottomSheetFooter';

const NewBottomSheetTitle = React.forwardRef<
  React.ElementRef<typeof NewBottomSheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof NewBottomSheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <NewBottomSheetPrimitive.Title
    ref={ref}
    className={cn('text-new-Sub-Title', className)}
    {...props}
  />
));
NewBottomSheetTitle.displayName = NewBottomSheetPrimitive.Title.displayName;

const NewBottomSheetDescription = React.forwardRef<
  React.ElementRef<typeof NewBottomSheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof NewBottomSheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <NewBottomSheetPrimitive.Description
    ref={ref}
    className={cn('text-new-Body2-medium', className)}
    {...props}
  />
));
NewBottomSheetDescription.displayName = NewBottomSheetPrimitive.Description.displayName;

export {
  NewBottomSheet,
  NewBottomSheetClose,
  NewBottomSheetContent,
  NewBottomSheetDescription,
  NewBottomSheetFooter,
  NewBottomSheetHeader,
  NewBottomSheetOverlay,
  NewBottomSheetPortal,
  NewBottomSheetTitle,
  NewBottomSheetTrigger,
};
